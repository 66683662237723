import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="feats-fighter-bonus"></a><h2>Feats</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Fighter Bonus</span>
    </h2>
    <h5>BLIND-FIGHT [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: In melee, every time you miss
because of concealment, you can reroll your miss chance percentile roll
one time to see if you actually hit.</p>
    <p>An invisible attacker gets no advantages related to hitting
you in melee. That is, you don&#8217;t lose your Dexterity bonus to Armor
Class, and the attacker doesn&#8217;t get the usual +2 bonus for being
invisible. The invisible attacker&#8217;s bonuses do still apply for ranged
attacks, however.</p>
    <p>You take only half the usual penalty to speed for being unable
to see. Darkness and poor visibility in general reduces your speed to
three-quarters normal, instead of one-half.</p>
    <p><b>Normal</b>: Regular attack roll modifiers for invisible
attackers trying to hit you apply, and you lose your Dexterity bonus to
AC. The speed reduction for darkness and poor visibility also applies.</p>
    <p><b>Special</b>: The Blind-Fight feat is of no use against a
character who is the subject of a blink spell.</p>
    <p>A fighter may select Blind-Fight as one of his fighter bonus
feats.</p>
    <h5>CLEAVE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Power Attack.</p>
    <p><b>Benefit</b>: If you deal a creature enough damage to make
it drop (typically by dropping it to below 0 hit points or killing it),
you get an immediate, extra melee attack against another creature
within reach. You cannot take a 5-foot step before making this extra
attack. The extra attack is with the same weapon and at the same bonus
as the attack that dropped the previous creature. You can use this
ability once per round.</p>
    <p><b>Special</b>: A fighter may select Cleave as one of his
fighter bonus feats.</p>
    <h5>COMBAT EXPERTISE [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Int 13.</p>
    <p><b>Benefit</b>: When you use the attack action or the full
attack action in melee, you can take a penalty of as much as &#8211;5 on your
attack roll and add the same number (+5 or less) as a dodge bonus to
your Armor Class. This number may not exceed your base attack bonus.
The changes to attack rolls and Armor Class last until your next action.</p>
    <p><b>Normal</b>: A character without the Combat Expertise feat
can fight defensively while using the attack or full attack action to
take a &#8211;4 penalty on attack rolls and gain a +2 dodge bonus to Armor
Class.</p>
    <p><b>Special</b>: A fighter may select Combat Expertise as one
of his fighter bonus feats.</p>
    <h5>COMBAT REFLEXES [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You may make a number of
additional attacks of opportunity equal to your Dexterity bonus.</p>
    <p>With this feat, you may also make attacks of opportunity while
flat-footed.</p>
    <p><b>Normal</b>: A character without this feat can make only one
attack of opportunity per round and can&#8217;t make attacks of opportunity
while flat-footed.</p>
    <p><b>Special</b>: The Combat Reflexes feat does not allow a
rogue to use her opportunist ability more than once per round.</p>
    <p>A fighter may select Combat Reflexes as one of his fighter
bonus feats.</p>
    <p>A monk may select Combat Reflexes as a bonus feat at 2nd level.</p>
    <h5>DEFLECT ARROWS [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Improved Unarmed
Strike.</p>
    <p><b>Benefit</b>: You must have at least one hand free (holding
nothing) to use this feat. Once per round when you would normally be
hit with a ranged weapon, you may deflect it so that you take no damage
from it. You must be aware of the attack and not flatfooted. Attempting
to deflect a ranged weapon doesn&#8217;t count as an action. Unusually
massive ranged weapons and ranged attacks generated by spell effects
can&#8217;t be deflected.</p>
    <p><b>Special:</b>A monk may select Deflect Arrows as a bonus
feat at 2nd level, even if she does not meet the prerequisites.</p>
    <p>A fighter may select Deflect Arrows as one of his fighter
bonus feats.</p>
    <h5>DODGE [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Dex 13.</p>
    <p><b>Benefit</b>: During your action, you designate an opponent
and receive a +1 dodge bonus to Armor Class against attacks from that
opponent. You can select a new opponent on any action.</p>
    <p>A condition that makes you lose your Dexterity bonus to Armor
Class (if any) also makes you lose dodge bonuses. Also, dodge bonuses
stack with each other, unlike most other types of bonuses.</p>
    <p><b>Special</b>: A fighter may select Dodge as one of his
fighter bonus feats.</p>
    <h5>EXOTIC WEAPON PROFICIENCY [GENERAL]</h5>
    <p className="initial">Choose a type of exotic weapon. You understand
how to use that type of exotic weapon in combat.</p>
    <p><b>Prerequisite</b>: Base attack bonus +1 (plus Str 13 for
bastard sword or dwarven waraxe).</p>
    <p><b>Benefit</b>: You make attack rolls with the weapon normally.</p>
    <p><b>Normal</b>: A character who uses a weapon with which he or
she is not proficient takes a &#8211;4 penalty on attack rolls.</p>
    <p><b>Special</b>: You can gain Exotic Weapon Proficiency
multiple times. Each time you take the feat, it applies to a new type
of exotic weapon. Proficiency with the bastard sword or the dwarven
waraxe has an additional prerequisite of Str 13.</p>
    <p>A fighter may select Exotic Weapon Proficiency as one of his
fighter bonus feats.</p>
    <h5>FAR SHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Point Blank Shot.</p>
    <p><b>Benefit</b>: When you use a projectile weapon, such as a
bow, its range increment increases by one-half (multiply by 1-1/2).
When you use a thrown weapon, its range increment is doubled.</p>
    <p><b>Special</b>: A fighter may select Far Shot as one of his
fighter bonus feats.</p>
    <h5>GREAT CLEAVE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Cleave, Power
Attack, base attack bonus +4.</p>
    <p><b>Benefit</b>: This feat works like Cleave, except that there
is no limit to the number of times you can use it per round.</p>
    <p><b>Special</b>: A fighter may select Great Cleave as one of
his fighter bonus feats.</p>
    <h5>GREATER TWO-WEAPON FIGHTING [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 19, Improved
Two-Weapon Fighting, Two-Weapon Fighting, base attack bonus +11.</p>
    <p><b>Benefit</b>: You get a third attack with your off-hand
weapon, albeit at a &#8211;10 penalty.</p>
    <p><b>Special</b>: A fighter may select Greater Two-Weapon
Fighting as one of his fighter bonus feats.</p>
    <p>An 11th-level ranger who has chosen the two-weapon combat
style is treated as having Greater Two-Weapon Fighting, even if he does
not have the prerequisites for it, but only when he is wearing light or
no armor.</p>
    <h5>GREATER WEAPON FOCUS [GENERAL]</h5>
    <p className="initial">Choose one type of weapon for which you have
already selected Weapon Focus. You can also choose unarmed strike or
grapple as your weapon for purposes of this feat.</p>
    <p><b>Prerequisites</b>: Proficiency with selected weapon, Weapon
Focus with selected weapon, fighter level 8th.</p>
    <p><b>Benefit</b>: You gain a +1 bonus on all attack rolls you
make using the selected weapon. This bonus stacks with other bonuses on
attack rolls, including the one from Weapon Focus (see below).</p>
    <p><b>Special</b>: You can gain Greater Weapon Focus multiple
times. Its effects do not stack. Each time you take the feat, it
applies to a new type of weapon.</p>
    <p>A fighter must have Greater Weapon Focus with a given weapon
to gain the Greater Weapon Specialization feat for that weapon.</p>
    <p>A fighter may select Greater Weapon Focus as one of his
fighter bonus feats.</p>
    <h5>GREATER WEAPON SPECIALIZATION [GENERAL]</h5>
    <p className="initial">Choose one type of weapon for which you have
already selected Weapon Specialization. You can also choose unarmed
strike or grapple as your weapon for purposes of this feat.</p>
    <p><b>Prerequisites</b>: Proficiency with selected weapon,
Greater Weapon Focus with selected weapon, Weapon Focus with selected
weapon, Weapon Specialization with selected weapon, fighter level 12th.</p>
    <p><b>Benefit</b>: You gain a +2 bonus on all damage rolls you
make using the selected weapon. This bonus stacks with other bonuses on
damage rolls, including the one from Weapon Specialization (see below).</p>
    <p><b>Special</b>: You can gain Greater Weapon Specialization
multiple times. Its effects do not stack. Each time you take the feat,
it applies to a new type of weapon.</p>
    <p>A fighter may select Greater Weapon Specialization as one of
his fighter bonus feats.</p>
    <h5>IMPROVED BULL RUSH [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Power Attack.</p>
    <p><b>Benefit</b>: When you perform a bull rush you do not
provoke an attack of opportunity from the defender. You also gain a +4
bonus on the opposed Strength check you make to push back the defender.</p>
    <p><b>Special</b>: A fighter may select Improved Bull Rush as one
of his fighter bonus feats.</p>
    <h5>IMPROVED CRITICAL [GENERAL]</h5>
    <p className="initial">Choose one type of weapon.</p>
    <p><b>Prerequisite</b>: Proficient with weapon, base attack bonus
+8.</p>
    <p><b>Benefit</b>: When using the weapon you selected, your
threat range is doubled.</p>
    <p><b>Special</b>: You can gain Improved Critical multiple times.
The effects do not stack. Each time you take the feat, it applies to a
new type of weapon.</p>
    <p>This effect doesn&#8217;t stack with any other effect that expands
the threat range of a weapon.</p>
    <p>A fighter may select Improved Critical as one of his fighter
bonus feats.</p>
    <h5>IMPROVED DISARM [GENERAL]</h5>
    <p className="initial">Prerequisites: Int 13, Combat Expertise.</p>
    <p><b>Benefit</b>: You do not provoke an attack of opportunity
when you attempt to disarm an opponent, nor does the opponent have a
chance to disarm you. You also gain a +4 bonus on the opposed attack
roll you make to disarm your opponent.</p>
    <p><b>Normal</b>: See the normal disarm rules.</p>
    <p><b>Special</b>: A fighter may select Improved Disarm as one of
his fighter bonus feats.</p>
    <p>A monk may select Improved Disarm as a bonus feat at 6th
level, even if she does not meet the prerequisites.</p>
    <h5>IMPROVED FEINT [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Int 13, Combat Expertise.</p>
    <p><b>Benefit</b>: You can make a Bluff check to feint in combat
as a move action.</p>
    <p><b>Normal</b>: Feinting in combat is a standard action.</p>
    <p>A fighter may select Improved Feint as one of his fighter
bonus feats.</p>
    <h5>IMPROVED GRAPPLE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Improved Unarmed
Strike.</p>
    <p><b>Benefit</b>: You do not provoke an attack of opportunity
when you make a touch attack to start a grapple. You also gain a +4
bonus on all grapple checks, regardless of whether you started the
grapple.</p>
    <p><b>Normal</b>: Without this feat, you provoke an attack of
opportunity when you make a touch attack to start a grapple.</p>
    <p><b>Special</b>: A fighter may select Improved Grapple as one
of his fighter bonus feats.</p>
    <p>A monk may select Improved Grapple as a bonus feat at 1st
level, even if she does not meet the prerequisites.</p>
    <h5>IMPROVED INITIATIVE [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +4 bonus on
initiative checks.</p>
    <p><b>Special</b>: A fighter may select Improved Initiative as
one of his fighter bonus feats.</p>
    <h5>IMPROVED OVERRUN [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Power Attack.</p>
    <p><b>Benefit</b>: When you attempt to overrun an opponent, the
target may not choose to avoid you. You also gain a +4 bonus on your
Strength check to knock down your opponent.</p>
    <p><b>Normal</b>: Without this feat, the target of an overrun can
choose to avoid you or to block you.</p>
    <p><b>Special</b>: A fighter may select Improved Overrun as one
of his fighter bonus feats.</p>
    <h5>IMPROVED PRECISE SHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 19, Point Blank
Shot, Precise Shot, base attack bonus +11.</p>
    <p><b>Benefit</b>: Your ranged attacks ignore the AC bonus
granted to targets by anything less than total cover, and the miss
chance granted to targets by anything less than total concealment.
Total cover and total concealment provide their normal benefits against
your ranged attacks.</p>
    <p>In addition, when you shoot or throw ranged weapons at a
grappling opponent, you automatically strike at the opponent you have
chosen.</p>
    <p><b>Normal</b>: See the normal rules on the effects of cover
and concealment. Without this feat, a character who shoots or throws a
ranged weapon at a target involved in a grapple must roll randomly to
see which grappling combatant the attack strikes.</p>
    <p><b>Special</b>: A fighter may select Improved Precise Shot as
one of his fighter bonus feats.</p>
    <p>An 11th-level ranger who has chosen the archery combat style
is treated as having Improved Precise Shot, even if he does not have
the prerequisites for it, but only when he is wearing light or no armor.</p>
    <h5>IMPROVED SHIELD BASH [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Shield Proficiency.</p>
    <p><b>Benefit</b>: When you perform a shield bash, you may still
apply the shield&#8217;s shield bonus to your AC.</p>
    <p><b>Normal</b>: Without this feat, a character who performs a
shield bash loses the shield&#8217;s shield bonus to AC until his or her next
turn.</p>
    <p><b>Special</b>: A fighter may select Improved Shield Bash as
one of his fighter bonus feats.</p>
    <h5>IMPROVED SUNDER [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Str 13, Power Attack.</p>
    <p><b>Benefit</b>: When you strike at an object held or carried
by an opponent (such as a weapon or shield), you do not provoke an
attack of opportunity (see Sunder, page 158).</p>
    <p>You also gain a +4 bonus on any attack roll made to attack an
object held or carried by another character.</p>
    <p><b>Normal</b>: Without this feat, you provoke an attack of
opportunity when you strike at an object held or carried by another
character.</p>
    <p><b>Special</b>: A fighter may select Improved Sunder as one of
his fighter bonus feats (see page 38).</p>
    <h5>IMPROVED TRIP [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Int 13, Combat Expertise.</p>
    <p><b>Benefit</b>: You do not provoke an attack of opportunity
when you attempt to trip an opponent while you are unarmed. You also
gain a +4 bonus on your Strength check to trip your opponent.</p>
    <p>If you trip an opponent in melee combat, you immediately get a
melee attack against that opponent as if you hadn&#8217;t used your attack
for the trip attempt. </p>
    <p><b>Normal</b>: Without this feat, you provoke an attack of
opportunity when you attempt to trip an opponent while you are unarmed.</p>
    <p><b>Special</b>: At 6th level, a monk may select Improved Trip
as a bonus feat, even if she does not have the prerequisites.</p>
    <p>A fighter may select Improved Trip as one of his fighter bonus
feats.</p>
    <h5>IMPROVED TWO-WEAPON FIGHTING [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 17, Two-Weapon
Fighting, base attack bonus +6.</p>
    <p><b>Benefit</b>: In addition to the standard single extra
attack you get with an off-hand weapon, you get a second attack with
it, albeit at a &#8211;5 penalty.</p>
    <p><b>Normal</b>: Without this feat, you can only get a single
extra attack with an off-hand weapon.</p>
    <p><b>Special</b>: A fighter may select Improved Two-Weapon
Fighting as one of his fighter bonus feats.</p>
    <p>A 6th-level ranger who has chosen the two-weapon combat style
is treated as having Improved Two-Weapon Fighting, even if he does not
have the prerequisites for it, but only when he is wearing light or no
armor.</p>
    <h5>IMPROVED UNARMED STRIKE [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You are considered to be armed
even when unarmed &#8212;that is, you do not provoke attacks of opportunity
from armed opponents when you attack them while unarmed. However, you
still get an attack of opportunity against any opponent who makes an
unarmed attack on you.</p>
    <p>In addition, your unarmed strikes can deal lethal or nonlethal
damage, at your option.</p>
    <p><b>Normal</b>: Without this feat, you are considered unarmed
when attacking with an unarmed strike, and you can deal only nonlethal
damage with such an attack.</p>
    <p><b>Special</b>: A monk automatically gains Improved Unarmed
Strike as a bonus feat at 1st level. She need not select it.</p>
    <p>A fighter may select Improved Unarmed Strike as one of his
fighter bonus feats.</p>
    <h5>MANYSHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 17, Point Blank
Shot, Rapid Shot, base attack bonus +6</p>
    <p><b>Benefit</b>: As a standard action, you may fire two arrows
at a single opponent within 30 feet. Both arrows use the same attack
roll (with a &#8211;4 penalty) to determine success and deal damage normally
(but see Special).</p>
    <p>For every five points of base attack bonus you have above +6,
you may add one additional arrow to this attack, to a maximum of four
arrows at a base attack bonus of +16. However, each arrow after the
second adds a cumulative &#8211;2 penalty on the attack roll (for a total
penalty of &#8211;6 for three arrows and &#8211;8 for four).</p>
    <p>Damage reduction and other resistances apply separately
against each arrow fired.</p>
    <p><b>Special</b>: Regardless of the number of arrows you fire,
you apply precision-based damage only once. If you score a critical
hit, only the first arrow fired deals critical damage; all others deal
regular damage.</p>
    <p>A fighter may select Manyshot as one of his fighter bonus
feats.</p>
    <p>A 6th-level ranger who has chosen the archery combat style is
treated as having Manyshot even if he does not have the prerequisites
for it, but only when he is wearing light or no armor.</p>
    <h5>MOBILITY [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Dodge.</p>
    <p><b>Benefit</b>: You get a +4 dodge bonus to Armor Class
against attacks of opportunity caused when you move out of or within a
threatened area. A condition that makes you lose your Dexterity bonus
to Armor Class (if any) also makes you lose dodge bonuses.</p>
    <p>Dodge bonuses stack with each other, unlike most types of
bonuses.</p>
    <p><b>Special</b>: A fighter may select Mobility as one of his
fighter bonus feats.</p>
    <h5>MOUNTED ARCHERY [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Ride 1 rank, Mounted
Combat.</p>
    <p><b>Benefit</b>: The penalty you take when using a ranged
weapon while mounted is halved: &#8211;2 instead of &#8211;4 if your mount is
taking a double move, and &#8211;4 instead of &#8211;8 if your mount is running.</p>
    <p><b>Special</b>: A fighter may select Mounted Archery as one of
his fighter bonus feats.</p>
    <h5>MOUNTED COMBAT [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Ride 1 rank.</p>
    <p><b>Benefit</b>: Once per round when your mount is hit in
combat, you may attempt a Ride check (as a reaction) to negate the hit.
The hit is negated if your Ride check result is greater than the
opponent&#8217;s attack roll. (Essentially, the Ride check result becomes the
mount&#8217;s Armor Class if it&#8217;s higher than the mount&#8217;s regular AC.)</p>
    <p><b>Special</b>: A fighter may select Mounted Combat as one of
his fighter bonus feats.</p>
    <h5>POINT BLANK SHOT [GENERAL]</h5>
    <p className="initial"><b>Benefit</b>: You get a +1 bonus on attack
and damage rolls with ranged weapons at ranges of up to 30 feet.</p>
    <p><b>Special</b>: A fighter may select Point Blank Shot as one
of his fighter bonus feats.</p>
    <h5>POWER ATTACK [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Str 13.</p>
    <p><b>Benefit</b>: On your action, before making attack rolls for
a round, you may choose to subtract a number from all melee attack
rolls and add the same number to all melee damage rolls. This number
may not exceed your base attack bonus. The penalty on attacks and bonus
on damage apply until your next turn.</p>
    <p><b>Special</b>: If you attack with a two-handed weapon, or
with a one-handed weapon wielded in two hands, instead add twice the
number subtracted from your attack rolls. You can&#8217;t add the bonus from
Power Attack to the damage dealt with a light weapon (except with
unarmed strikes or natural weapon attacks), even though the penalty on
attack rolls still applies. (Normally, you treat a double weapon as a
one-handed weapon and a light weapon. If you choose to use a double
weapon like a two-handed weapon, attacking with only one end of it in a
round, you treat it as a two-handed weapon.)</p>
    <p>A fighter may select Power Attack as one of his fighter bonus
feats.</p>
    <h5>PRECISE SHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Point Blank Shot.</p>
    <p><b>Benefit</b>: You can shoot or throw ranged weapons at an
opponent engaged in melee without taking the standard &#8211;4 penalty on
your attack roll.</p>
    <p><b>Special</b>: A fighter may select Precise Shot as one of
his fighter bonus feats.</p>
    <h5>QUICK DRAW [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Base attack bonus +1.</p>
    <p><b>Benefit</b>: You can draw a weapon as a free action instead
of as a move action. You can draw a hidden weapon (see the Sleight of
Hand skill) as a move action.</p>
    <p>A character who has selected this feat may throw weapons at
his full normal rate of attacks (much like a character with a bow).</p>
    <p><b>Normal</b>: Without this feat, you may draw a weapon as a
move action, or (if your base attack bonus is +1 or higher) as a free
action as part of movement. Without this feat, you can draw a hidden
weapon as a standard action.</p>
    <p><b>Special</b>: A fighter may select Quick Draw as one of his
fighter bonus feats.</p>
    <h5>RAPID RELOAD [GENERAL]</h5>
    <p className="initial">Choose a type of crossbow (hand, light, or
heavy).</p>
    <p><b>Prerequisite</b>: Weapon Proficiency (crossbow type chosen).</p>
    <p><b>Benefit</b>: The time required for you to reload your
chosen type of crossbow is reduced to a free action (for a hand or
light crossbow) or a move action (for a heavy crossbow). Reloading a
crossbow still provokes an attack of opportunity.</p>
    <p>If you have selected this feat for hand crossbow or light
crossbow, you may fire that weapon as many times in a full attack
action as you could attack if you were using a bow.</p>
    <p><b>Normal</b>: A character without this feat needs a move
action to reload a hand or light crossbow, or a full-round action to
reload a heavy crossbow.</p>
    <p><b>Special</b>: You can gain Rapid Reload multiple times. Each
time you take the feat, it applies to a new type of crossbow.</p>
    <p>A fighter may select Rapid Reload as one of his fighter bonus
feats.</p>
    <h5>RAPID SHOT [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Point Blank Shot.</p>
    <p><b>Benefit</b>: You can get one extra attack per round with a
ranged weapon. The attack is at your highest base attack bonus, but
each attack you make in that round (the extra one and the normal ones)
takes a &#8211;2 penalty. You must use the full attack action to use this
feat.</p>
    <p><b>Special</b>: A fighter may select Rapid Shot as one of his
fighter bonus feats.</p>
    <p>A 2nd-level ranger who has chosen the archery combat style is
treated as having Rapid Shot, even if he does not have the
prerequisites for it, but only when he is wearing light or no armor.</p>
    <h5>RIDE-BY ATTACK [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Ride 1 rank, Mounted
Combat.</p>
    <p><b>Benefit</b>: When you are mounted and use the charge
action, you may move and attack as if with a standard charge and then
move again (continuing the straight line of the charge). Your total
movement for the round can&#8217;t exceed double your mounted speed. You and
your mount do not provoke an attack of opportunity from the opponent
that you attack.</p>
    <p><b>Special</b>: A fighter may select Ride-By Attack as one of
his fighter bonus feats.</p>
    <h5>SHOT ON THE RUN [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Dodge, Mobility,
Point Blank Shot, base attack bonus +4.</p>
    <p><b>Benefit</b>: When using the attack action with a ranged
weapon, you can move both before and after the attack, provided that
your total distance moved is not greater than your speed.</p>
    <p><b>Special</b>: A fighter may select Shot on the Run as one of
his fighter bonus feats.</p>
    <h5>SNATCH ARROWS [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 15, Deflect Arrows,
Improved Unarmed Strike.</p>
    <p><b>Benefit</b>: When using the Deflect Arrows feat you may
catch the weapon instead of just deflecting it. Thrown weapons can
immediately be thrown back at the original attacker (even though it
isn&#8217;t your turn) or kept for later use.</p>
    <p>You must have at least one hand free (holding nothing) to use
this feat.</p>
    <p><b>Special</b>: A fighter may select Snatch Arrows as one of
his fighter bonus feats.</p>
    <h5>SPIRITED CHARGE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Ride 1 rank, Mounted
Combat, Ride-By Attack.</p>
    <p><b>Benefit</b>: When mounted and using the charge action, you
deal double damage with a melee weapon (or triple damage with a lance).</p>
    <p><b>Special</b>: A fighter may select Spirited Charge as one of
his fighter bonus feats.</p>
    <h5>SPRING ATTACK [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Dodge, Mobility,
base attack bonus +4.</p>
    <p><b>Benefit</b>: When using the attack action with a melee
weapon, you can move both before and after the attack, provided that
your total distance moved is not greater than your speed. Moving in
this way does not provoke an attack of opportunity from the defender
you attack, though it might provoke attacks of opportunity from other
creatures, if appropriate. You can&#8217;t use this feat if you are wearing
heavy armor.</p>
    <p>You must move at least 5 feet both before and after you make
your attack in order to utilize the benefits of Spring Attack.</p>
    <p><b>Special</b>: A fighter may select Spring Attack as one of
his fighter bonus feats.</p>
    <h5>STUNNING FIST [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Wis 13, Improved
Unarmed Strike, base attack bonus +8.</p>
    <p><b>Benefit</b>: You must declare that you are using this feat
before you make your attack roll (thus, a failed attack roll ruins the
attempt). Stunning Fist forces a foe damaged by your unarmed attack to
make a Fortitude saving throw (DC 10 + 1/2 your character level + your
Wis modifier), in addition to dealing damage normally. A defender who
fails this saving throw is stunned for 1 round (until just before your
next action). A stunned character can&#8217;t act, loses any Dexterity bonus
to AC, and takes a &#8211;2 penalty to AC. You may attempt a stunning attack
once per day for every four levels you have attained (but see Special),
and no more than once per round. Constructs, oozes, plants, undead,
incorporeal creatures, and creatures immune to critical hits cannot be
stunned.</p>
    <p><b>Special</b>: A monk may select Stunning Fist as a bonus
feat at 1st level, even if she does not meet the prerequisites. A monk
who selects this feat may attempt a stunning attack a number of times
per day equal to her monk level, plus one more time per day for every
four levels she has in classes other than monk.</p>
    <p>A fighter may select Stunning Fist as one of his fighter bonus
feats.</p>
    <h5>TRAMPLE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Ride 1 rank, Mounted
Combat.</p>
    <p><b>Benefit</b>: When you attempt to overrun an opponent while
mounted, your target may not choose to avoid you. Your mount may make
one hoof attack against any target you knock down, gaining the standard
+4 bonus on attack rolls against prone targets.</p>
    <p><b>Special</b>: A fighter may select Trample as one of his
fighter bonus feats.</p>
    <h5>TWO-WEAPON DEFENSE [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 15, Two-Weapon
Fighting.</p>
    <p><b>Benefit</b>: When wielding a double weapon or two weapons
(not including natural weapons or unarmed strikes), you gain a +1
shield bonus to your AC.</p>
    <p>When you are fighting defensively or using the total defense
action, this shield bonus increases to +2.</p>
    <p><b>Special</b>: A fighter may select Two-Weapon Defense as one
of his fighter bonus feats.</p>
    <h5>TWO-WEAPON FIGHTING [GENERAL]</h5>
    <p className="initial">You can fight with a weapon in each hand. You
can make one extra attack each round with the second weapon.</p>
    <p><b>Prerequisite</b>: Dex 15.</p>
    <p><b>Benefit</b>: Your penalties on attack rolls for fighting
with two weapons are reduced. The penalty for your primary hand lessens
by 2 and the one for your off hand lessens by 6.</p>
    <p><b>Normal</b>: If you wield a second weapon in your off hand,
you can get one extra attack per round with that weapon. When fighting
in this way you suffer a &#8211;6 penalty with your regular attack or attacks
with your primary hand and a &#8211;10 penalty to the attack with your off
hand. If your off-hand weapon is light the penalties are reduced by 2
each. (An unarmed strike is always considered light.)</p>
    <p><b>Special</b>: A 2nd-level ranger who has chosen the
two-weapon combat style is treated as having Two-Weapon Fighting, even
if he does not have the prerequisite for it, but only when he is
wearing light or no armor.</p>
    <p>A fighter may select Two-Weapon Fighting as one of his fighter
bonus feats.</p>
    <h5>WEAPON FINESSE [GENERAL]</h5>
    <p className="initial"><b>Prerequisite</b>: Base attack bonus +1.</p>
    <p><b>Benefit</b>: With a light weapon, rapier, whip, or spiked
chain made for a creature of your size category, you may use your
Dexterity modifier instead of your Strength modifier on attack rolls.
If you carry a shield, its armor check penalty applies to your attack
rolls.</p>
    <p><b>Special</b>: A fighter may select Weapon Finesse as one of
his fighter bonus feats.</p>
    <p>Natural weapons are always considered light weapons.</p>
    <h5>WEAPON FOCUS [GENERAL]</h5>
    <p className="initial">Choose one type of weapon. You can also choose
unarmed strike or grapple (or ray, if you are a spellcaster) as your
weapon for purposes of this feat.</p>
    <p><b>Prerequisites</b>: Proficiency with selected weapon, base
attack bonus +1.</p>
    <p><b>Benefit</b>: You gain a +1 bonus on all attack rolls you
make using the selected weapon.</p>
    <p><b>Special</b>: You can gain this feat multiple times. Its
effects do not stack. Each time you take the feat, it applies to a new
type of weapon.</p>
    <p>A fighter may select Weapon Focus as one of his fighter bonus
feats. He must have Weapon Focus with a weapon to gain the Weapon
Specialization feat for that weapon.</p>
    <h5>WEAPON SPECIALIZATION [GENERAL]</h5>
    <p className="initial">Choose one type of weapon for which you have
already selected the Weapon Focus feat. You can also choose unarmed
strike or grapple as your weapon for purposes of this feat. You deal
extra damage when using this weapon.</p>
    <p><b>Prerequisites</b>: Proficiency with selected weapon, Weapon
Focus with selected weapon, fighter level 4th.</p>
    <p><b>Benefit</b>: You gain a +2 bonus on all damage rolls you
make using the selected weapon.</p>
    <p><b>Special</b>: You can gain this feat multiple times. Its
effects do not stack. Each time you take the feat, it applies to a new
type of weapon.</p>
    <p>A fighter may select Weapon Specialization as one of his
fighter bonus feats.</p>
    <h5>WHIRLWIND ATTACK [GENERAL]</h5>
    <p className="initial"><b>Prerequisites</b>: Dex 13, Int 13, Combat
Expertise, Dodge, Mobility, Spring Attack, base attack bonus +4.</p>
    <p><b>Benefit</b>: When you use the full attack action, you can
give up your regular attacks and instead make one melee attack at your
full base attack bonus against each opponent within reach.</p>
    <p>When you use the Whirlwind Attack feat, you also forfeit any
bonus or extra attacks granted by other feats, spells, or abilities.</p>
    <p><b>Special</b>: A fighter may select Whirlwind Attack as one
of his fighter bonus feats.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      